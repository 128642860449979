import React from 'react'
import PropTypes from 'prop-types' 
import { graphql }  from 'gatsby'

import Img from '../components/AtomImg'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import ActiveSearch from '../components/ActiveSearch'
import Text from '../components/Text'

import H4 from './../components/H4'
import Div from './../components/Div'
import Button from './../components/Button'
import Hr from '../components/Hr'
import Article from '../components/Article'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Div
          maxWidth={['100%', 390, 728]}
        >
          <Row
            pt={[50, 70]}
            pb={[24]}
            pl={[8, 8, 0, 0]}
            pr={[8, 8, 0, 0]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <div>
                <H4
                  color='black'
                  variant='highEmphasis'
                  align='left'
                >
                  Blog
                </H4>
              </div>
            </Column>
          </Row>
          <Row
            pl={[6, 6, 0, 0]}
            pr={[6, 6, 0, 0]}
          >
            <Div
              maxWidth={['100%', 390, 728]}
            >
              <ActiveSearch 
                mx={[null]}
              />
            </Div>
          </Row>
        </Div>
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[30]}
            mt={[30, 60]}
            mx={[-16, -16, 0, 0]}
            pl={[8, 8, 0, 0]}
            pr={[8, 8, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 328, 328]}
              >
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['flex-start']}
                textAlign={['left']}
              >
                <Div
                  maxWidth={[290, '100%', 352]}
                >
                  <Div
                    style={{
                      fontSize: 11.8,
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      color: 'rgba(0, 0, 0, 0.6)',
                      textTransform: 'uppercase',
                      marginBottom: 8
                    }}
                  >
                    12 Fev 2019 • Leitura 5 min
                  </Div>
                  <Text
                    fontSize={[23.7]}
                    style={{
                      fontWeight: '600',
                      color: 'rgba(0, 0, 0, 0.87)'
                    }}
                    mb={[5, 5, 3]}
                  >
                    Quer economizar? Não escolha o servidor dedicado mais barato
                  </Text>
                </Div>
                <Div
                  display={['block', 'block', 'none']}
                  maxWidth={['100%', 328, 328, 328]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={['100%', 422, 728, 728]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 728]}
          alignItems={['center', 'center', 'stretch']}
        >
          <Row
            pb={[50]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Row
                flexDirection={['column', 'column', 'row']}
                mt={[40]}
                mb={[50]}
                maxWidth={['100%', 344, '100%']}
              >
                <Column
                  width={[1, 1, 1/2]}
                  mb={[50, 50, 0]}
                >
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                </Column>
                <Column
                  width={[1, 1, 1/2]}
                >
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                </Column>
              </Row>
              <Button
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['200px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  margin: '0 auto'
                }}
              >
                Carregar mais
              </Button>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={['100%', 422, 728, 728]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 680]}
          alignItems={['center', 'center', 'stretch']}
        >
          <Row
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Div
                flexDirection='row'
                alignItems='center'
                justifyContent='left'
                maxWidth={['100%', 344, '100%']}
              >
                <H4
                  selected
                  on='onSurface'
                  variant='highEmphasis'
                >
                  Mais Lidas
                </H4>
              </Div>
              <Row
                flexDirection={['column', 'column', 'row']}
                mt={[40]}
                mb={[50]}
                maxWidth={['100%', 344, '100%']}
              >
                <Column
                  width={[1, 1, 1/2]}
                  mb={[50, 50, 0]}
                >
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                </Column>
                <Column
                  width={[1, 1, 1/2]}
                >
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                  <Article
                    title='Quais mudanças a LGPD trará para o setor da saúde?'
                    meta={{
                      date: '22 Mar 2019',
                      duration: '6 min'
                    }}
                  />
                </Column>
              </Row>
              <Button
                schema='white'
                outlined
                selected
                variant='base'
                on='onSurface'
                width={['100%']}
                maxWidth={['200px']}
                style={{
                  display: 'flex',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  margin: '0 auto'
                }}
              >
                Carregar mais
              </Button>
            </Column>
          </Row>
        </Div>
      </Div>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Blog' />
      <PageTemplate
        title='Blog'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
    image1: file(relativePath: { eq: "blog.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
